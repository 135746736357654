<template>
    <div class="container">
        <headerComponent />

        <div style="margin: 5px auto;padding: 5px 0;width: 90%;">
            <el-input placeholder="请输入用户ID或名称" v-model="searchUserVal" class="input-with-select" clearable>
                <el-button slot="append" icon="el-icon-search" @click="initGetUserFunc(1)"></el-button>
            </el-input>
        </div>

        <!-- pagination -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />

        <!-- show data list -->
        <div v-for="(item, index) in allList" :key="index" class="box_shadow_show admUserBox" @click="handleFunc(item)"
            v-loading="loading">
            <div style="flex: 1;">
                <div>ID: {{ item.id }}</div>
                <div>账号: {{ item.nickname }}</div>
                <div>会员: {{ item.isVip }}</div>
                <div>状态: {{ item.normal == 1 ? '正常使用' : '封禁中' }}</div>
            </div>
            <div style="flex: 1;font-size: 14px;color: #a3a3a3;">
                <div>优惠码: {{ item.coupon }}</div>
                <div>注册时间：{{ item.create }}</div>
                <div>开始时间：{{ item.vipStart }}</div>
                <div>结束时间：{{ item.vipEnd }}</div>
            </div>
        </div>

        <!-- handle -->
        <div class="otherDialog">
            <el-dialog title="编辑信息" :visible.sync="dialogFormVisible" :modal="false">
                <el-form :model="formItem">
                    <el-form-item label="ID" :label-width="formLabelWidth">
                        <el-input v-model="formItem.id" :disabled="true" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号" :label-width="formLabelWidth">
                        <el-input v-model="formItem.nickname" :disabled="true" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号状态" :label-width="formLabelWidth">
                        <el-select v-model="formItem.normal" placeholder="请选择账号状态">
                            <el-option label="正常使用" value="1"></el-option>
                            <el-option label="封禁账号" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="会员天数" :label-width="formLabelWidth">
                        <el-input v-model="isVipNum" autocomplete="off" placeholder="设置会员天数"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitFunc()">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <footerComponent></footerComponent>
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import headerComponent from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";

export default {
    components: {
        headerComponent,
        footerComponent,
        scrollTop,
        pageComponent
    },
    data() {
        return {
            allList: [],
            totalCount: 0,
            total: 0,
            currentPage: 1,
            enteredPage: 1,
            loading: true,
            searchUserVal: "",

            // 展示编辑
            dialogFormVisible: false,
            formItem: {},
            formLabelWidth: '120px',
            isVipNum: ''

        }
    },
    created() {
        this.initGetUserFunc(this.currentPage);
    },
    watch: {
        currentPage(newPage) {
            this.initGetUserFunc(newPage);
        },
    },
    methods: {
        initGetUserFunc(page) {
            this.$post("api/v1/adm/admUserApi/", {
                pageNum: page,
                search: this.searchUserVal,
            }).then(
                (res) => {
                    this.allList = res.body.data;
                    this.totalCount = res.body.count;
                    this.total = res.body.total;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.message);
                }
            );
        },

        handleFunc(item) {
            this.dialogFormVisible = true
            this.formItem = item
        },
        submitFunc() {
            this.$set(this.formItem, 'isVipNum', this.isVipNum)
            this.$put("api/v1/adm/admUserApi/", this.formItem).then(res => {
                this.$msgSuccess()
                this.dialogFormVisible = false
                this.initGetUserFunc(this.currentPage);
            }, err => {
                this.$msgError(err.message)
            })

        },
    }

};
</script>
