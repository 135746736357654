<template>
    <div class="container">
      <!-- header tarbar -->
      <headerVue />
  
      <!-- pagination -->
      <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />
  
      <!-- show data -->
      <div class="detailBox">
        <imageList :allList="actorList" :loading="loading" />
      </div>
  
      <!-- pagination -->
      <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />
  
      <!-- footer footerStr:"" -->
      <!-- <footerComponent /> -->
  
      <!-- scrollTop -->
      <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
  </template>
  
  <script>
  import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
  import imageList from "../../componentVue/imageListComponent/imageListComponent.vue";
  import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
  import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
  import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";
  
  export default {
    components: {
      headerVue,
      imageList,
      pageComponent,
      footerComponent,
      scrollTop
    },
    data() {
      return {
        actorList: [],
        totalCount: 0,
        total: 0,
        currentPage: 1,
        enteredPage: 1,
        actorName: null,
        loading: true,
      };
    },
    watch: {
      currentPage(newPage) {
        this.getActorFunc(this.actorName, newPage);
      },
    },
    created() {
      let name = this.$route.params.name;
      if (name) window.sessionStorage.setItem("actorName", name);
      else name = window.sessionStorage.getItem("actorName");
      this.actorName = name;
      var currPage = window.sessionStorage.getItem("actorPage")
      if (currPage) this.currentPage = Number(currPage)
      this.getActorFunc(name, this.currentPage);
    },
    methods: {
      getActorFunc(name, page) {
        window.sessionStorage.setItem("actorPage", page)
        this.loading = true
        this.$post("api/v1/actor/", { name, pageNum: page }).then(
          (res) => {
            this.actorList = res.body.data;
            this.totalCount = res.body.count;
            this.total = res.body.total;
            this.loading = false;
          },
          (err) => {
            this.$msgError(err.message);
          }
        );
      },
    },
  };
  </script>
  
  <style scoped></style>
  