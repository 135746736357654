import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// 自定义封装element ui
import "./plugins/element.js";
// 自定义封装接口请求
import { get, post, put } from './plugins/http.js';
// 引入全局样式
import "./assets/css/global.css";
import "./assets/css/tarbar.css";
// 引入iconfont，第三方的在main.js中引入，iconfont是矢量图标（登录界面）
import "./assets/iconfont_1/iconfont.css";
import socket from './plugins/socket.js';

import Loading from './plugins/loading.js';
Vue.use(Loading);

Vue.prototype.$socket = socket;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL; // api的base_url

// Vue 启动时的生产提示
Vue.config.productionTip = false;

// 全局范围内使用这些封装的通知方法 - 调用 this.$notifySuccess(message)
Vue.prototype.$msgSuccess = function (message, title = 'Success') {
  this.$notify.success({ title: title, message: message });
};
Vue.prototype.$msgError = function (message, title = 'Fail') {
  this.$notify.error({ title: title, message: message });
};


// copy text
Vue.prototype.$copyText = function (copyStr) {
  navigator.clipboard.writeText(copyStr).then(() => {
    this.$notify.success({ title: "Success", message: "已復製內容" });
  }).catch(err => {
    console.error('Failed to copy text: ', err);
  });
};

var debug = false;
Vue.prototype.$showBgImg = debug; // login里面或其他页面 展示的背景图片
const openBtnState = debug  // 取消禁用？
if (openBtnState) { } else {
  // 禁用右键菜单
  document.addEventListener('contextmenu', function (event) {
    event.preventDefault();
  }, false);
  // 禁用快捷键
  document.addEventListener('keydown', function (event) {
    // 禁用 F12
    if (event.keyCode === 123) {
      event.preventDefault();
    }
    // 禁用 Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+Shift+C, Ctrl+U
    if ((event.ctrlKey && event.shiftKey && (event.keyCode === 73 || event.keyCode === 74 || event.keyCode === 67)) || (event.ctrlKey && event.keyCode === 85)) {
      event.preventDefault();
    }
  }, false);
}


new Vue({
  router,
  created() {
    this.$socket.on('onPrompt', data => {
      this.$msgSuccess(data.data)
    });
  },
  render: (h) => h(App),
}).$mount("#app");
