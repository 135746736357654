<template>
    <div class="container">
        <headerComponent />

        <div style="margin: 5px auto;padding: 5px 0;width: 90%; display: flex;" v-loading="loading">
            <div style="margin: 5px; color: #3079ce;" @click="updateBxFunc()">更新轮播图</div>
            <div style="margin: 5px; color: #3079ce;" @click="updateFreeVideoFunc()">更新免费播放</div>
        </div>

        <!-- pagination -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />

        <!-- show data list -->
        <div v-for="(item, index) in allList" :key="index" class="box_shadow_show admUserBox" @click="handleFunc(item)"
            v-loading="loading">
            <div style="flex: 1;">
                <div>ID: {{ item.id }}</div>
                <div>账号: {{ item.name }}</div>
                <div>密码: {{ item.pwd }}</div>
                <div>状态: {{ item.isVip == 1 ? '会员' : '非会员' }}</div>
            </div>
        </div>

        <!-- handle -->
        <div class="otherDialog">
            <el-dialog title="编辑信息" :visible.sync="dialogFormVisible" :modal="false">
                <el-form :model="formItem">
                    <el-form-item label="ID" :label-width="formLabelWidth">
                        <el-input v-model="formItem.id" :disabled="true" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号" :label-width="formLabelWidth">
                        <el-input v-model="formItem.name" :disabled="true" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                        <el-input v-model="formItem.pwd" :value="formItem.normal" :disabled="true"
                            autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号状态" :label-width="formLabelWidth">
                        <el-select v-model="formItem.isVip" placeholder="请选择账号状态">
                            <el-option label="会员账号" value="1"></el-option>
                            <el-option label="普通账号" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="success" @click="checkAccountFunc()">验 证</el-button>
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitFunc()">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <!-- create spider account -->
        <div class="addCss">
            <i class="el-icon-circle-plus addIcon" @click="dialogFormVisibleCreate = true"></i>
            <el-dialog title="创建账号" :visible.sync="dialogFormVisibleCreate" :modal="false">
                <el-form :model="formAuth">
                    <el-form-item label="账号" :label-width="formLabelWidth">
                        <el-input v-model="formAuth.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                        <el-input v-model="formAuth.pwd" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="是否会员" :label-width="formLabelWidth">
                        <el-input v-model="formAuth.isVip" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibleCreate = false">取 消</el-button>
                    <el-button type="primary" @click="createSpiderAccountFunc()">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <footerComponent></footerComponent>
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import headerComponent from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";

export default {
    components: {
        headerComponent,
        footerComponent,
        scrollTop,
        pageComponent
    },
    data() {
        return {
            allList: [],
            totalCount: 0,
            total: 0,
            currentPage: 1,
            enteredPage: 1,
            loading: true,
            searchUserVal: "",

            // 展示编辑
            dialogFormVisible: false,
            formItem: {},
            formLabelWidth: '120px',
            isVipNum: '',

            // 创建账号
            dialogFormVisibleCreate: false,
            formAuth: {
                name: '',
                pwd: '', isVip: 0
            }

        }
    },
    created() {
        this.initGetUserFunc(this.currentPage);
    },
    watch: {
        currentPage(newPage) {
            this.initGetUserFunc(newPage);
        },
    },
    methods: {
        // get
        initGetUserFunc(page) {
            this.$get("api/v1/adm/admSpiderApi/", {
                pageNum: page,
                search: this.searchUserVal,
            }).then(
                (res) => {
                    this.allList = res.body.data;
                    this.totalCount = res.body.count;
                    this.total = res.body.total;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.message);
                }
            );
        },

        // update
        handleFunc(item) {
            this.dialogFormVisible = true
            this.formItem = item
        },
        submitFunc() {
            this.$put("api/v1/adm/admSpiderApi/", this.formItem).then(res => {
                this.$msgSuccess()
                this.dialogFormVisible = false
                this.initGetUserFunc(this.currentPage);
            }, err => {
                this.$msgError(err.message)
            })

        },

        // create
        createSpiderAccountFunc() {
            this.$post("api/v1/adm/admSpiderApi/", this.formAuth).then(res => {
                this.$msgSuccess()
                this.dialogFormVisibleCreate = false
                this.initGetUserFunc(this.currentPage);
            }, err => {
                this.$msgError(err.message)
            })
        },

        // check account
        checkAccountFunc() {
            this.loading = true
            this.$post("/api/v1/adm/admCheckSpiderApi/", this.formItem).then((res) => {
                this.$msgSuccess(res.body.data)
                this.loading = false
            }, (err) => {
                this.$msgError(err.message);
            }
            );
        },

        // update free vide
        updateFreeVideoFunc() {
            this.$alert('是否确认更新视频', '更新免费视频', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        this.loading = true;
                        this.$post("api/v1/adm/admUpdateFreeVideoApi/").then(res => {
                            this.$msgSuccess()
                            this.loading = false;
                        }, err => {
                            this.$msgError(err.message)
                        })
                    }
                }
            });
        },

        // update bbs
        updateBxFunc() {
            this.$alert('是否确认更新轮播图', '更新轮播图', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        this.loading = true;
                        this.$post("api/v1/adm/admUpdateBbsApi/").then(res => {
                            this.$msgSuccess()
                            this.loading = false;
                        }, err => {
                            this.$msgError(err.message)
                        })
                    }
                }
            });
        },
    }

};
</script>
