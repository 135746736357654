<template>
  <div>
    <el-container class="home-container">
      <el-header>
        <div>
          <el-link class="backBtnOrHome" :underline="false" v-if="$route.path !== '/'"
            @click="switchswitchHandle('goBack')">返回</el-link>
          <el-link class="backBtnOrHome" :underline="false" @click="switchswitchHandle('goHome')">首頁</el-link>
          <el-dropdown trigger="click">
            <span class="el-avatar el-dropdown-link">
              {{ selectName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in links" :key="index"
                @click.native="switchswitchHandle(item.action, item.text, item.is_params)">
                {{ item.text }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "headerVue",
  data() {
    return {
      links: [],
      isLogin: false,
      userName: "",
      dropdownItem: [],
      selectName: "類型選擇"
    };
  },
  props: {
    isSearchPage: Boolean,
  },
  mounted() {
    this.isHomePage = this.$route.path === "/";
    this.getInitHeader();
    const chooseName = window.sessionStorage.getItem('chooseName');
    this.selectName = chooseName ? chooseName : "類型選擇"
  },
  watch: {
    "$route.path": function (newPath) {
      this.isHomePage = newPath === "/";
    },
  },
  methods: {
    getInitHeader() {
      this.$socket.emit('requestData', { "isType": 'getHeader' });
      this.$socket.on("getHeader", data => {
        this.links = !this.isHomePage ? data.data : data.data.filter(item => item.text !== '返回');
        window.sessionStorage.setItem('staticUrl', data.staticUrl)
        window.sessionStorage.setItem('staticName', data.staticName)
      })
    },
    switchswitchHandle(action, name, params) {
      if (name) {
        window.sessionStorage.setItem('chooseName', name);
        this.selectName = name
      }
      const actions = {
        goActionFunc: () => this.goActionFunc(params),
        goBack: () => this.goBack(),
        goHome: () => this.goHome(),
      };
      if (actions[action]) actions[action]();
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    goBack() {
      window.sessionStorage.setItem('chooseName', "類型選擇");
      this.$router.go(-1);
    },
    goHome() {
      window.sessionStorage.setItem('chooseName', "類型選擇");
      this.navigateTo("/");
    },
    goActionFunc(params) {
      this.navigateTo({ name: "allPage", params: { t: params } });
    },
  },
};
</script>




<style lang="less" scoped>
/* 改进后的CSS示例 */
.home-container {
  height: 100%;
  width: 90%;
  margin: auto;
}

.el-header {
  height: 50px;
  margin: 20px 0;
  border-radius: 8px;
  background-color: #f1f3f3;
  /* 更改了颜色 */
  display: flex;
  justify-content: space-between;
  padding-left: 1%;
  align-items: center;
  color: #ffffff;
  /* 优化字体颜色 */
  font-size: 20px;
  /* 修改了字号 */
}

.el-link.el-link--default {
  color: #2a81cb;
  /* 淡化蓝色以适应背景 */
  font-weight: bold;
  /* 增加字体粗细 */
}

.backBtnOrHome {
  margin: 0 10px;
  /* 增加间距 */
  font-size: 18px;
  /* 增大字体尺寸 */
}

.el-avatar {
  background: none;
  display: unset;
  color: #2a81cb;
  margin: 0 10px;
}
</style>