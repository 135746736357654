<template>
    <div class="container">
        <headerComponent />

        <div style="margin: auto;width: 90%;margin-bottom: 10px;">
            <el-input placeholder="请输入搜索詞" v-model="searchVal" class="input-with-select" clearable
                @input="searchMoreFunc">
                <el-button slot="append" icon="el-icon-search" @click="searchMoreFunc"></el-button>
            </el-input>
        </div>

        <!-- pagination -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />

        <!-- show data -->
        <div class="detailBox">
            <imageList :allList="allList" :loading="loading" :category="category" />
        </div>

        <!-- pagination -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />

        <!-- scrollTop -->
        <scrollTop :scrollDom="'.container'"></scrollTop>

    </div>
</template>

<script>
import headerComponent from "../../componentVue/headerComponent/headerComponent.vue";
import imageList from "../../componentVue/imageListComponent/imageListComponent.vue";
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";

export default {
    components: {
        headerComponent,
        imageList,
        pageComponent,
        footerComponent,
        scrollTop
    },
    data() {
        return {
            allList: [],
            totalCount: 0,
            total: 0,
            currentPage: 1,
            enteredPage: 1,
            category: null,
            loading: true,
            searchVal: ""
        };
    },
    watch: {
        currentPage(newPage) {
            this.getAllFunc(this.category, newPage);
        },
        '$route.params.t': {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.searchVal = "";
                    this.category = newVal;
                    let storedPage = window.sessionStorage.getItem("allPage" + newVal);
                    if (!storedPage) this.currentPage = 1;
                    else this.currentPage = Number(storedPage);
                    this.loading = true;
                    this.getAllFunc(newVal, this.currentPage);
                }
            }
        }
    },
    created() {
        let category = this.$route.params.t;
        if (category) window.sessionStorage.setItem("allPageCategory", category);
        else category = window.sessionStorage.getItem("allPageCategory");
        this.category = category;
        var currPage = window.sessionStorage.getItem("allPage" + category);
        if (currPage) this.currentPage = Number(currPage);
        this.getAllFunc(category, this.currentPage);
    },
    methods: {
        getAllFunc(category, page) {
            window.sessionStorage.setItem("allPage" + category, page);
            let tagName = window.sessionStorage.getItem("tagName");
            this.loading = true;
            this.$post("api/v1/more/", {
                isType: category, pageNum: page, tag: tagName, search: this.searchVal
            }).then(
                (res) => {
                    this.allList = res.body.data;
                    this.totalCount = res.body.count;
                    this.total = res.body.total;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.message);
                }
            );
        },
        searchMoreFunc() {
            this.currentPage = 1;
            this.getAllFunc(this.category, this.currentPage)
        },
    },
};
</script>

<style scoped></style>