<template>
    <div class="container">
        <headerComponent />
        <carouselChart />
        <div style="margin-bottom: 10px;">
            <marquee :marqueeText="marqueeText" />
        </div>
        <freeComponent isType="free" isSize="8" isTitle="免费放映" :isShowMore="true" />
        <freeComponent isType="hot" isSize="15" isTitle="熱門放映" :isShowMore="true" />
        <footerComponent></footerComponent>
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import headerComponent from "../../componentVue/headerComponent/headerComponent.vue";
import carouselChart from "../../componentVue/carouselChart/carouselChart.vue";
import marquee from "../../componentVue/marqueeComponent/marqueeComponent.vue";
import freeComponent from "../../componentVue/freeComponent/freeComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";

export default {
    name: "HomePage",
    components: {
        headerComponent,
        carouselChart,
        marquee,
        freeComponent,
        footerComponent,
        scrollTop
    },
    data() {
        return {
            marqueeText: "警告：账号禁止共享"
        }
    },
    mounted() {
        this.$socket.emit('requestData', { "isType": 'getNotice' });
        this.$socket.on("getNotice", data => {
            this.marqueeText = data
        })
    },

};
</script>