import axios from 'axios';
import router from "../router";

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
    timeout: 60000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
    config => {
        // 在这里你可以做一些请求前的操作
        // const method = config.method.toUpperCase();
        // config.headers['Content-Type'] = method == 'GET' ? 'application/json' : 'application/x-www-form-urlencoded';
        const token = window.sessionStorage.getItem("token")
        config.headers['Accept'] = 'application/json';
        config.headers['Authorization'] = 'Bearer ' + token; // 确保 token 已经定义
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// response拦截器
service.interceptors.response.use(
    response => {
        var vCode = response.data.code
        // 验证一些特定的code
        if (vCode == "000000") {
            return response.data;
        }
        else if (vCode == "400003" || vCode == "410002" || vCode == "410003" || vCode == "410004" || vCode == "420003") {
            window.sessionStorage.clear()
            router.push("/");
            return Promise.reject(response.data);
        }
        else {
            return Promise.reject(response.data);
        }
    },
    error => {
        if (error.response && error.response.status === 422) {
            router.push("/");
        }
        return Promise.reject(error);
    }
);

// 封装get方法
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.get(url, { params: params })
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
}

// 封装post方法
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        service.post(url, data)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            });
    });
}

// 封装put方法
export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        service.put(url, data)
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            });
    });
}
