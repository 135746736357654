// loading-plugin.js
import LoadingComponent from '../components/componentVue/Loading/Loading.vue';

const Loading = {
  install(Vue, options) {
    const VueLoading = Vue.extend(LoadingComponent);
    const vm = new VueLoading();
    vm.$mount(document.createElement('div'));
    document.body.appendChild(vm.$el);

    Vue.prototype.$loading = {
      show: () => vm.show(),
      hide: () => vm.hide(),
    };
  }
};

export default Loading;
