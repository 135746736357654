<template>
    <div class="container">
        <headerComponent />

        <!-- show data list -->
        <div v-for="(item, index) in allList" :key="index" class="box_shadow_show admUserBox" @click="handleFunc(item)">
            <div style="flex: 1;">
                <div>ID: {{ item.id }}</div>
                <div>名称: {{ item.text }}</div>
                <div>func: {{ item.action }}</div>
                <div>状态: {{ item.status == 1 ? '打开' : '关闭' }}</div>
            </div>
        </div>

        <!-- handle -->
        <div class="otherDialog" v-loading="loading">
            <el-dialog title="编辑信息" :visible.sync="dialogFormVisible" :modal="false">
                <el-form :model="formItem">
                    <el-form-item label="ID" :label-width="formLabelWidth">
                        <el-input v-model="formItem.id" :disabled="true" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="名称" :label-width="formLabelWidth">
                        <el-input v-model="formItem.text" :disabled="true" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="开关" :label-width="formLabelWidth">
                        <el-input v-model="formItem.status" :value="formItem.status" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitFunc()">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <footerComponent></footerComponent>
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import headerComponent from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";

export default {
    components: {
        headerComponent,
        footerComponent,
        scrollTop,
        pageComponent
    },
    data() {
        return {
            allList: [],
            loading: true,

            // 展示编辑
            dialogFormVisible: false,
            formItem: {},
            formLabelWidth: '120px',
            isVipNum: ''

        }
    },
    created() {
        this.initGetUserFunc(this.currentPage);
    },
    watch: {
        currentPage(newPage) {
            this.initGetUserFunc(newPage);
        },
    },
    methods: {
        initGetUserFunc(page) {
            this.$post("api/v1/adm/admCategoryApi/", {
                pageNum: page,
                search: this.searchUserVal,
            }).then(
                (res) => {
                    this.allList = res.body.data;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.message);
                }
            );
        },

        handleFunc(item) {
            this.dialogFormVisible = true
            this.formItem = item
        },
        submitFunc() {
            this.$put("api/v1/adm/admCategoryApi/", this.formItem).then(res => {
                this.$msgSuccess()
                this.dialogFormVisible = false
                this.initGetUserFunc(this.currentPage);
            }, err => {
                this.$msgError(err.message)
            })

        },
    }

};
</script>
