<template>
    <div class="container">
        <headerVue />

        <div class="tjBox">
            <div class="header">
                <h1>優惠碼使用</h1>
                <p>您只需要簡單操作即可完成申請, 流程清晰明瞭, 快捷方便</p>
            </div>
            <div class="features">
                <div class="feature" v-for="(item, index) in descLs">
                    <img :src="item.img" alt="">
                    <p>{{ item.title }}</p>
                    <p>{{ item.desc }}</p>
                </div>
            </div>
        </div>

        <!-- 會員商品 -->
        <div class="personalBoxVip" v-loading="loading">
            <div class="vipOption" v-for="(items, index) in vipShopList" :key="index" @click="selectItem(items)"
                :style="{ borderColor: selectedItem === items ? 'aquamarine' : '#ccc' }">
                <div :style="{ color: selectedItem === items ? 'red' : '' }">
                    {{ items.name }}
                </div>
                <div :style="{ color: selectedItem === items ? 'red' : '' }">
                    會員價格：{{ items.price }}￥
                </div>
                <div :style="{ color: selectedItem === items ? 'red' : '' }">
                    會員描述：{{ items.desc }}
                </div>
                <div v-if="selectedItem === items">
                    <el-input placeholder="請輸入優惠碼" v-model="couponVal" class="input-with-select" clearable></el-input>
                </div>
                <div v-if="selectedItem === items" class="buyBtn" @click="buyBtnFunc(items.id)">
                    去購買
                </div>
            </div>
        </div>

        <!-- footer -->
        <footerComponent />
    </div>
</template>

<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
export default {
    components: {
        headerVue,
        footerComponent,
    },
    data() {
        return {
            baseUrl: "api/v1/vip-shop/",
            basePayUrl: "", // 支付鏈接獲取接口
            vipShopList: [],
            loading: true,
            selectedItem: null,
            couponVal: "",
            descLs: [],
        };
    },
    created() { },
    mounted() {
        this.getVipShopFunc();
    },
    methods: {
        // 獲取會員商品
        getVipShopFunc() {
            this.$post(this.baseUrl).then(
                (res) => {
                    this.vipShopList = res.body.data;
                    this.loading = false;
                    this.basePayUrl = res.body.payUrl
                    this.descLs = res.body.desc
                },
                (err) => {
                    this.$msgError(err.message);
                }
            );
        },
        // 選取商品
        selectItem(item) {
            this.selectedItem = item;
        },
        // 去購買
        buyBtnFunc(vid) {
            if (!this.basePayUrl) {
                this.$msgError("接口失效 請聯系客服處理")
            } else {
                this.loading = true;
                this.$post(this.basePayUrl, { vid, coupon: this.couponVal }).then(
                    (res) => {
                        this.loading = false;
                        this.$msgSuccess("正在生成訂單");
                        window.location.href = res.body.data; // 跳轉到支付頁面
                        // window.open(res.data, '_blank');
                    },
                    (err) => {
                        this.$msgError(err.message);
                        this.loading = false;
                    }
                );
            }
        },
    },
};
</script>

<style>
/* 推广介绍 */
.tjBox {
    width: 90%;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 5px;
    text-align: center;
}

.header h1 {
    color: #333;
}

.features {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.feature {
    width: 30%;
}

.feature img {
    vertical-align: middle;
    border-style: none;
}

/* buy vip */
.personalBoxVip {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 5px;
    justify-content: center;
}

.vipOption {
    width: 100%;
    box-sizing: border-box;
    margin: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;

    >div {
        padding: 3px;
        font-size: 14px;
    }
}

.buyBtn {
    text-align: center;
    background: aquamarine;
    color: #fff;
    margin-top: 5px;
    border-radius: 2px;
}


@media (min-width: 600px) {
    .vipOption {
        width: 22%;
        margin: 10px;
        border: 1px solid #ccc;
        padding: 10px;
        background-color: #f9f9f9;
    }
}
</style>
