<template>
    <div class="container-box">
        <div class="nav">
            <ul>
                <li v-for="(item, index) in navItems" :key="index" :class="{ active: activeIndex === index }"
                    @click="setActive(index)" :style="{ '--clr': item.color }">
                    <span style="color:#fff;font-weight: 800;">
                        <i :class="item.icon"></i>
                        {{ item.text }}
                    </span>
                </li>
                <div class="indicator" :style="indicatorStyle"></div>
            </ul>
        </div>
        <component :is="currentComponent"></component>
    </div>
</template>


<script>
import AdmUserPage from "./AdmUserPage.vue";
import AdmCategoryPage from "./AdmCategoryPage.vue";
import AdmSpiderPage from "./AdmSpiderPage.vue";

const componentMap = {
    AdmUserPage,
    AdmCategoryPage,
    AdmSpiderPage,
};

export default {
    data() {
        return {
            activeIndex: 0,
            navItems: []
        };
    },
    mounted() {
        this.getTarBarFunc()
    },
    computed: {
        liWidth() {
            return this.navItems.length ? 350 / this.navItems.length : 0;
        },
        indicatorStyle() {
            const offset = this.activeIndex * this.liWidth + (this.liWidth - 70) / 2;
            return {
                transform: `translateX(${offset}px)`
            };
        },
        currentComponent() {
            return this.navItems.length ? this.navItems[this.activeIndex].component : null;
        },
    },
    methods: {
        getTarBarFunc() {
            this.$post("api/v1/adm/getAdmTarBar/").then(
                (res) => {
                    this.navItems = res.body.map(item => ({
                        ...item,
                        component: componentMap[item.component]
                    }));
                },
                (err) => {
                    this.$msgError(err.message);
                }
            );
        },
        setActive(index) {
            this.activeIndex = index;
        }
    }
};
</script>



<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}

a {
    text-decoration: none;
}

body {
    min-height: 100vh;
    overflow-y: hidden;
}

.admUserBox {
    width: 90%;
    padding: 10px;
    margin: 10px auto;
    display: flex;
    border-radius: 5px;
}

/* 在屏幕宽度大于601px并且小于1024px的设备上设置宽度为35% */
@media (min-width: 601px) and (max-width: 1024px) {
    .admUserBox {
        width: 70%;
    }
}

/* 在屏幕宽度大于1280px的设备上设置宽度为25% */
@media (min-width: 1280px) {
    .admUserBox {
        width: 25%;
    }
}

/* 在屏幕宽度小于等于601px的设备上保持宽度为90% */
@media (max-width: 601px) {
    .admUserBox {
        width: 90%;
    }
}
</style>
