<template>
    <div class="container">
        <headerVue :isSearchPage="true" />
        <!-- 支付结果 -->
        <div class="pay-result">
            <i :class="showIcon ? success : errorIcon"></i>
            <p class="tit">{{ tit }}</p>
            <p class="tip">{{ tip }}</p>
            <div class="btn" v-if="price">支付金額：{{ price }}</div>
            <p class="alert">
                <i class="el-icon-warning green"></i>
                {{ msg }}
            </p>
        </div>
    </div>
</template>
<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
export default {
    name: 'payResultPage',
    components: {
        headerVue
    },
    data() {
        return {
            baseApiUrl: "api/v1/checkOrder/",
            success: "el-icon-success green",
            errorIcon: "el-icon-error red",
            showIcon: true,
            tit: "訂單支付成功",
            tip: "返回首頁個人中心即可查看會員時間",
            price: 0,
            msg: '溫馨貼士: 虛擬商品 暫不支持退款操作.',
        };
    },
    mounted() {
        const outTradeNo = this.$route.query.out_trade_no; // 使用$route.query获取out_trade_no
        if (!outTradeNo) {
            this.$msgError("查無此訂單")
            this.showIcon = false
            this.tit = "查無此訂單"
            this.tip = "請返回首頁購買會員"
        } else {
            this.$loading.show();
            this.$post(this.baseApiUrl, { outTradeNo }).then(res => {
                this.$loading.hide();
                this.price = res.body[0].price
            }, err => {
                this.$loading.hide();
                this.$msgError(err.message);
                this.showIcon = false
                this.tit = "訂單支付失敗"
                this.tip = err.message
            })
        }
    },
}
</script>
<style scoped>
.pay-result {
    width: 90%;
    margin: auto;
    padding: 50px 0;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}

.el-icon-success {
    font-size: 80px;
}

.green {
    color: #1dc779;
}

.el-icon-error {
    font-size: 80px;
}

.red {
    color: #df1515;
}

.tit {
    font-size: 20px;
    margin-top: 20px;
}

.tip {
    color: #666;
    font-size: 14px;
}

p {
    line-height: 1.6;
    margin: 10px 0;
}

.btn {
    display: inline-block;
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #1dc779;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #149e5c;
}

.alert {
    font-size: 12px;
    color: #666;
    margin-top: 30px;
    line-height: 1.5;
    padding: 0 30px;
}

@media (max-width: 768px) {
    .el-icon-success {
        font-size: 60px;
    }

    .tit {
        font-size: 18px;
    }

    .tip,
    .alert {
        font-size: 12px;
    }

    .btn {
        padding: 8px 16px;
    }
}
</style>
