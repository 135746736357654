<template>
    <div class="container">
        <headerVue />
        <div class="chat-window">
            <div class="content">
                <!-- 聊天消息展示 輸入框 -->
                <div class="chat-content">

                    <!-- 消息展示 -->
                    <div ref="messageContainer" class="message-container" @scroll="handleScroll"
                        @click="handleContainerClick">
                        <div v-if="showLoadMore" class="load-more">
                            <el-button @click="loadMoreMessages">加載更多</el-button>
                        </div>
                        <div v-for="message in messages" :key="message.id" class="message"
                            :class="{ 'right': message.nickname === authName, 'left': message.nickname !== authName }">
                            <div class="message-text" :class="{
                                'isMe msg-right': message.nickname === authName,
                                'isCs msg-left': message.nickname !== authName,
                                'transparent-background': message.msgType === 2
                            }">
                                <template v-if="message.msgType === 1">
                                    {{ message.content }}
                                </template>
                                <template v-else-if="message.msgType === 2">
                                    <img :src="message.content" class="upload-image"
                                        @click="openModal(message.content)" />
                                </template>
                            </div>
                        </div>
                    </div>

                    <!-- 輸入聊天訊息 -->
                    <form @submit.prevent="sendMessage">
                        <div class="chatInputBox">
                            <div class="chatInput">
                                <input type="text" class="inputCss" placeholder="請輸入訊息" @keyup.enter="sendMessage"
                                    v-model="inputText">
                            </div>
                            <div>
                                <!-- 顯示發送圖片 或 發送文字 -->
                                <i class="el-icon-chat-line-round" v-if="inputText" style="cursor: pointer;"
                                    @click="sendMessage"></i>
                                <i class="el-icon-camera" v-else style="cursor: pointer;"
                                    @click="triggerFileUpload"></i>
                                <input type="file" ref="fileInput" capture style="display: none;"
                                    @change="handleFileUpload">
                            </div>
                            <!-- 放大圖片查看 -->
                            <div v-if="showImgModal" class="modal" @click="closeModal">
                                <img :src="modalImageUrl" class="modal-image">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- <footerComponent/> -->
    </div>
</template>

<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";

export default {
    components: {
        headerVue,
        footerComponent
    },
    data() {
        return {
            roomId: this.$route.params.id,
            roomMsg1: '您好 歡迎使用在聊, 有什麼問題可以在此處提交！客服並非24H在線',
            roomMsg2: '您好 歡迎使用在聊, 輸入 開通會員 自動給你開通',
            authName: window.sessionStorage.getItem('user'),
            inputText: '',
            messages: [],
            page: 1,  // 当前页码
            pageSize: 15,  // 每页条数
            totalMessages: 0,  // 总消息数
            showLoadMore: false,  // 是否显示加载更多按钮
            scrollPosition: 0,  // 记录滚动位置

            // 是否放大展示图片
            showImgModal: false,
            modalImageUrl: '',
        };
    },

    mounted() {
        this.getChatMsgFunc(this.roomId);
        this.subscribeToNewMessages();
    },

    methods: {
        getChatMsgFunc(roomId, page = 1, pageSize = 15) {
            this.$loading.show(); // 显示Loading
            if (roomId == 2) {
                const token = window.sessionStorage.getItem('token');
                this.$socket.emit('getChatMsgCs', {
                    token,
                    roomId,
                    page,
                    pageSize
                });
                this.$loading.hide(); // 显示Loading
            } else {
                this.$socket.emit('requestData', {
                    "isType": 'getChatMsg',
                    roomId,
                    page,
                    pageSize
                });
            }
            this.$socket.once('getChatMsg', data => {
                this.totalMessages = data.total;
                var pageNo = data.pageNo;
                var roomMsg = this.roomId == 1 ? this.roomMsg1 : this.roomMsg2
                if (pageNo * pageSize >= this.totalMessages) {
                    data.data.unshift({ content: roomMsg, isMe: false, msgType: 1 });
                }
                if (pageNo === 1) {
                    this.messages = data.data;
                } else {
                    this.messages = data.data.concat(this.messages);
                }
                this.$loading.hide();
                this.$nextTick(() => {
                    if (pageNo === 1) {
                        this.scrollToBottom();
                    } else {
                        this.scrollToPosition();
                    }
                });
            });
        },


        // 每次提交返回 监听最新的消息
        subscribeToNewMessages() {
            if (this.roomId != 2) {
                this.$socket.on('newChatMessage', (message) => {
                    this.messages.push(message);
                    this.$loading.hide();
                    this.$nextTick(() => {
                        this.scrollToBottom();
                    });
                });
            } else {
                let username = window.sessionStorage.getItem('user')
                let emitName = `auto_${this.roomId}_${username}`
                this.$socket.on(emitName, (message) => {
                    this.messages.push(message);
                    this.$loading.hide();
                    this.$nextTick(() => {
                        this.scrollToBottom();
                    });
                });
            }
        },


        // 加载更多
        loadMoreMessages() {
            if ((this.page * this.pageSize) < this.totalMessages) {
                this.scrollPosition = this.$refs.messageContainer.scrollHeight - this.$refs.messageContainer.scrollTop;  // 保存当前滚动位置相对于底部的距离
                this.page++;
                this.getChatMsgFunc(this.roomId, this.page, this.pageSize);
            } else {
                this.$msgSuccess("暫無更多訊息")
            }
        },

        // 发送消息
        sendMessage(event) {
            if (event && event.type === 'keyup') {
                event.preventDefault();
            }
            const content = this.inputText.trim();
            if (content) {
                this.$loading.show()
                const token = window.sessionStorage.getItem('token');
                if (!token) this.$loading.hide();
                this.$socket.emit("createChatMsg", {
                    token,
                    content,
                    nickname: this.authName,
                    roomId: this.roomId
                });
                this.inputText = '';
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            }
        },

        // 滚动底部
        scrollToBottom() {
            const container = this.$refs.messageContainer;
            if (container) {
                container.scrollTop = container.scrollHeight;
            }
        },

        // 顶部
        scrollToPosition() {
            const container = this.$refs.messageContainer;
            if (container) {
                container.scrollTop = container.scrollHeight - this.scrollPosition;
            }
        },

        // 展示操作
        handleScroll() {
            const container = this.$refs.messageContainer;
            if (container.scrollTop === 0) {
                this.showLoadMore = true;
            } else {
                this.showLoadMore = false;
            }
        },


        // upload file
        triggerFileUpload() {
            this.$refs.fileInput.click();
        },
        async handleFileUpload(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith('image/')) {
                this.$loading.show("正在发送中..."); // 显示Loading
                try {
                    // 创建 FormData 对象并添加文件
                    const formData = new FormData();
                    formData.append('file', file);  // 'image' 是发送到服务器的字段名
                    formData.append('roomId', this.roomId);  // 'image' 是发送到服务器的字段名
                    this.$post("/api/v1/msgUpload/", formData).then(res => {
                        // 执行图片上传后的逻辑，如显示图片等
                        // this.imageUrls.push(imageUrl);
                        const imageUrl = res.body.url;
                        this.messages.push({
                            content: imageUrl,
                            nickname: this.authName,
                            msgType: 2 // 2 for image
                        });
                        this.$nextTick(() => {
                            this.scrollToBottom();
                        });
                        this.$loading.hide();
                    }, err => {
                        this.$loading.hide();
                        this.$msgError(err.message);
                    })
                } catch (error) {
                    this.$loading.hide();
                    console.error('Error uploading the image: ', error);
                }
            } else {
                this.$msgError("Please upload an image file.")
            }
        },
        handleContainerClick(event) {
            const target = event.target;
            if (target.classList.contains('upload-image')) {
                this.openModal(target.src);
            }
        },
        openModal(imageUrl) {
            this.modalImageUrl = imageUrl;
            this.showImgModal = true;
        },
        closeModal() {
            this.showImgModal = false;
        }
    }
};
</script>

<style scoped>
.container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

headerVue,
footerComponent {
    height: 50px;
}

.chat-window {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    overflow: hidden;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.chat-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #f5f5f5;
    /* Change the background to a lighter shade */
}


.message-container {
    overflow-y: auto;
    flex-grow: 1;
    padding: 10px 10px 60px;
    /* 增加底部padding，可能需要根据输入框高度调整 */
}

.message {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 3px 0;
}

.message-text {
    max-width: 60%;
    padding: 12px 18px;
    font-size: 16px;
    border-radius: 18px;
    line-height: 1.5;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
}

.msg-left {
    align-self: flex-start;
    background: #fff;
}

.msg-right {
    align-self: flex-end;
    background: #0084ff;
    color: white;
}

.msg-right.transparent-background {
    background: transparent !important;
    /* 使用 !important 以确保覆盖其他样式定义 */
}


.isMe {
    border-radius: 18px 3px 18px 18px;
}

.isCs {
    border-radius: 3px 18px 18px 18px;
}

.upload-image {
    max-width: 100%;
    cursor: pointer;
    border-radius: 8px;
}


/* 确保图标垂直居中 */
.el-icon-camera {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.el-icon-chat-line-round {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

input:focus {
    outline: none;
}

.chatInputBox {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    height: 50px;
    box-sizing: border-box;
    width: 90%;
    background: #fff;
}

.chatInput {
    flex-grow: 1;
    margin-right: 10px;
}

.inputCss {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
}

.load-more {
    text-align: center;
    margin: 10px 0;
}


/* show Image */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-image {
    max-width: 90%;
    max-height: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.25s ease-in-out;
}

.modal:hover .modal-image {
    transform: scale(1.05);
}
</style>
