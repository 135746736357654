<template>
    <div class="container chatBox">
        <div class="chatCss">
            <headerComponent />
            <div class="addCss" v-if="auth >= 2">
                <i class="el-icon-circle-plus addIcon" @click="dialogFormVisible = true"></i>
                <el-dialog title="創建在聊" :visible.sync="dialogFormVisible" :modal="false">
                    <el-form :model="form">
                        <el-form-item label="聊天室名稱" :label-width="formLabelWidth">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="聊天室描述" :label-width="formLabelWidth">
                            <el-input v-model="form.desc" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="聊天室logo" :label-width="formLabelWidth">
                            <el-input v-model="form.src" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false"> 消</el-button>
                        <el-button type="primary" @click="createChatRoomFunc()">確 認</el-button>
                    </div>
                </el-dialog>
            </div>
            <div class="chatLi box_shadow_show" v-for="(item, index) in chatRoomList" :key="index"
                @click="onlineChat(item.id)">
                <img :src="item.src" class="chatLiImg"></img>
                <div style="margin-left: 10px;">
                    <div style="font-weight: 800;">{{ item.name }}</div>
                    <div style="font-size: 12px; color: #a3a39e;margin-top: 3px;">當前在聊: {{ item.joinCnt }}人</div>
                    <div style="font-size: 12px; color: #a3a39e;margin-top: 3px;">{{ item.desc }}</div>
                </div>
            </div>
            <footerComponent></footerComponent>
        </div>
    </div>
</template>

<script>
import headerComponent from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
export default {
    components: {
        headerComponent,
        footerComponent
    },
    data() {
        let url = window.sessionStorage.getItem("staticUrl")
        let name = window.sessionStorage.getItem("staticName")
        return {
            auth: window.sessionStorage.getItem("auth"),
            dialogFormVisible: false,
            form: {
                name: '',
                desc: '',
                src: `${url}avatar_1eTTDXdRKSjzSNkz/${name}.png`
            },
            formLabelWidth: '120px',
            chatRoomList: []
        }
    },
    mounted() {
        this.getChatRoomList()
    },
    methods: {
        getChatRoomList() {
            this.$loading.show()
            this.$socket.emit('requestData', { "isType": 'getChatList' });
            this.$socket.on('getChatList', data => {
                this.chatRoomList = data
                this.$loading.hide()
            });
        },
        onlineChat(id) {
            this.$router.push({ name: "chat", params: { id } });
        },
        createChatRoomFunc() {
            this.$post("api/v1/adm/createChatRoom/", this.form).then(
                (res) => {
                    this.dialogFormVisible = false
                },
                (err) => {
                    this.$msgError(err.message);
                }
            );
        },
    }
}
</script>

<style>
.chatBox {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.chatCss {
    width: 100%;
    max-width: 420px;
    background-color: #fff;
    border: 1px solid #f3f3f3;
}

.chatItem {
    background-color: #2389ff;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chatLi {
    width: 90%;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    background-color: #fbfbfb;
    margin: auto;
    margin-top: 10px;
    /* align-items: center; */
}

.chatLiImg {
    width: 80px;
    height: 80px;
    border-radius: 5px;
}
</style>
