<template>
  <div class="detailBox">
    <el-carousel indicator-position="outside" class="carousel" arrow="always" v-loading="loading">
      <el-carousel-item v-for="(image, index) in images" :key="index">
        <img :src="image.img" :alt="'Image ' + (index + 1)" class="cardImg" @click="toActorFunc(image.aid)" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

export default {
  name: "carouselChart",
  data() {
    return {
      baseApi: "api/v1/carouselChart/",
      loading: true,
      images: [],
      currentIndex: 0,
    };
  },
  mounted() {
    this.carouselChartData();
  },
  methods: {
    carouselChartData() {
      this.$get(this.baseApi).then(res => {
        this.images = res.body.data;
        this.loading = false
      }, err => {
        this.$msgError(err.message)
      })
    },
    toActorFunc(aid) {
      this.$post(this.baseApi, { aid }).then(res => {
        const name = res.body.name
        this.$router.push({ name: "actor", params: { name } });
      }, err => {
        this.$msgError(err.message)
      })
    },
  },
};
</script>


<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

/* 默认情况下使用手机端样式 */
.carousel::v-deep .el-carousel__container,
.cardImg {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 5px;
}

@media (min-width: 601px) {

  /* 在PC端，carousel和cardImg的高度为450px */
  .carousel::v-deep .el-carousel__container,
  .cardImg {
    height: 450px;
  }
}
</style>
